import React, {useEffect, useState} from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";
import {FiImage} from "react-icons/fi";
import {InputLabeled} from "../index";

const ImageLabeled = props => {

    const {data, section, id, token, dataItemUpdate, local, heading, onChange, initValue} = props;
    const {imageComponent: {placeholderText, descriptionText, loadingText, urlText}} = props.texts.components

    const [isImageErrored, setIsImageErrored] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [imageValue, setImageValue] = useState(initValue || null)

    useEffect(() => {
        if (initValue !== undefined) {
            setImageValue(initValue);
        }
    }, [initValue]);

    const onBtnEmptyClickHandler = () => {
        setImageValue('')
        if (local) {
            onChange && onChange('')
        } else {
            dataItemUpdate({
                id: id,
                value: "",
                action: "update"
            })
        }
    }

    const onChangeHandler = (value) => {

        setIsImageErrored(false)
        setImageValue(value);

        if (local) {
            onChange && onChange(value)
        } else {
            dataItemUpdate({
                id: id,
                value: value,
                action: "update"
            })
        }

    }

    const uploadHandler = () => {

        const imageData = new FormData()
        imageData.append('image', selectedFile)

        fetch(process.env.REACT_APP_BACKEND + 'images/' + data._id, {
            method: 'POST',
            body: imageData,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
            .then(response => response.json())
            .then(success => {
                if (success.result && success.result.src) {
                    setImageValue(success.result.src);
                    if (local) {
                        onChange && onChange(success.result.src)
                    } else {
                        dataItemUpdate({
                            id: id,
                            value: success.result.src,
                            action: "update"
                        })
                    }
                    setIsImageErrored(false)
                }
            })
                .catch(error => console.log(error)
            );
    }

    const dataItem =  getItemById(data[section], id);
    const imageInputValue = local ? imageValue : dataItem.value;


    return (
        <div>
            <div>
                <p className='mb-10 fs-14-12'>{heading || dataItem.label}</p>

                <div className='d-flex mb-20 wrapMobile'>
                    <div className='mrweb-20 mbmob-20 mobileBlock'>
                        {!isImageErrored && imageInputValue &&
                            <img className='form-image' src={imageInputValue} onError={() => setIsImageErrored(true)} alt="form"/>
                        }
                        {(isImageErrored || !imageInputValue) &&
                        <div className='form-image-placeholder'>
                            <FiImage size={50} color='#cccccc' />
                            <p className='mt-10 fs-12'>{placeholderText}</p>
                        </div>
                        }
                    </div>
                    <div className='wp-100'>
                        <div className='mobileBlock'>
                            <p className='fs-12'><strong>{loadingText}</strong></p>
                            <p className='fs-10 fcgray mt-5 mb-15'>{descriptionText}</p>
                            <div className='d-flex-wrap-ac mtmob-10'>
                                <input className="w-290 mr-10" type="file" accept="image/*" onChange={(e) => setSelectedFile(e.target.files[0])}/>
                                <button className="button-alt mr-10 mtmob-10" disabled={(selectedFile === null)} onClick={uploadHandler}>Загрузить</button>
                                {(imageValue === null || imageValue !== '') &&
                                    <button className="button-link mtmob-10" onClick={onBtnEmptyClickHandler}>Очистить</button>
                                }
                            </div>
                        </div>

                        <div className="mt-20 mb-10 mobileBlock">
                            <p className='fs-12 mb-5'><strong>{urlText}</strong></p>
                            <InputLabeled type='url' mandatory initValue={imageInputValue} onChange={onChangeHandler}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ user: { data, section, token }, language: { texts }}) => {
    return { data, section, token, texts };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageLabeled);
