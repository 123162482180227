import React, {useState, useEffect} from "react";
import {dataItemUpdate} from "../../../actions";
import {connect} from "react-redux";
import {getItemById} from "../../../utils";
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import CheckBox from "../common/form-checkbox";
import {ImageLabeled, InputLabeled} from "../index";
import SlidingPane from "react-sliding-pane";
import {getTextDate} from "../../../utils/dates";
import SaveButton from "../../savebutton/save-button";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {isUniversity, theme} from "../../../utils/keys";
import APIService from "../../../services/api-service";
import Multiselect from 'multiselect-react-dropdown';

const apiService = new APIService()

const subTypes = [
    'агроэкотуризм',
    'военно-исторический',
    'гастрономический',
    'культурно-познавательный',
    'лечебно-оздоровительный',
    'ностальгический',
    'охотничий',
    'промышленный',
    'религиозный',
    'спортивный',
    'событийный',
    'экологический',
]

const SightItem = props => {

    const { width } = useWindowDimensions();

    const [mapItems, setMapItems] = useState([])
    const [currentItem, setCurrentItem] = useState(null)

    const loadMapItems = async () => {
        try {
            const result = await apiService.getMapItems()
            if (result) {
                console.log(result)
                setMapItems(result)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const createMapItem = async () => {
        try {
            const result = await apiService.createMapItem({
                title: 'Новая достопримечательность',
                description: 'Описание достопримечательности'
            })
            if (result) {
                setMapItems(result)
                window.alert('Новая достопримечательность добавлена в конце')
            }
        } catch (e) {
            console.log(e)
        }
    }

    const updateMapItem = async () => {
        try {
            const objToProceed = {...currentItem}
            delete objToProceed._id
            const result = await apiService.updateMapItem(currentItem._id, {
                ...objToProceed,
                city: data.mapCity
            })
            if (result) {
                setMapItems(result)
                window.alert('Информация обновлена успешно')
            }
        } catch (e) {
            console.log(e)
        }
    }

    const deleteMapItem = async () => {
        try {
            const result = await apiService.deleteMapItem(currentItem._id)
            if (result) {
                setMapItems(result)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        loadMapItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (currentItem) {
            const exists = mapItems.find(el => el._id === currentItem._id)
            if (exists) {
                setCurrentItem(exists)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(mapItems)])

    const [state, setState] = useState({
        isPaneOpen: false,
        isPaneOpenLeft: false,
    });


    const openNewsWindow = (item) => {
        setCurrentItem(item)
        setState({isPaneOpen: true})
    }

    const {data, section, id, texts, dataItemUpdate} = props;
    const {newsComponent: {
        newsHeadingText,
        newsImageText,
        newsDeleteText,
        newsContentText,
        newsDateText,
        newsExpDateText,
        newsExpTimeText,
        newsMainText,
        newsSpecialText,
        pollMainText,
        menuMainText,
        newsOffersText,
        vacancyOffersText,
        newsTimeText,
        newsTitleText,
        newsUrlText,
        newsPreviewHeadingText,
        newsPreviewContentText
    }} = texts.components

    const [expirationExpanded, setExpirationExpanded] = useState(false)

    const onChangeHandler = (type, value) => {

        if (currentItem) {
            const copyItem = {...currentItem}
            copyItem[type] = value
            setCurrentItem(copyItem)
        }
    }

    const onSubTypeHandler = (value) => {
        onChangeHandler('subTypes', (value || []).map(el => el.name))
    }

    const subTypesOptions = (subTypes || []).map((item, index) => {return {name: item, id: index}})
    const subTypesOptionsSelected = (currentItem?.subTypes || []).map((item, index) => {return {name: item, id: index}})

    return (
        <div>
            <div>
                <button className={'button'} onClick={() => createMapItem()}>Добавить достопримечательность</button>
                <div className={'separator1px mt-10'} />
            </div>
            {mapItems.map((mapItem, mapItemIndex) => {
                const {title, description, image} = mapItem
                return <div key={mapItemIndex}>
                    <div className='mt-10 d-flex-ac enabledClick' onClick={() => openNewsWindow(mapItem)}>
                        {image &&
                            <div className='mr-20'>
                                <img className='image-preview' src={image} alt='news' />
                            </div>
                        }
                        <div className='mr-20'>
                            <p className='fcblue fs-14'><strong>{title || ''}</strong></p>
                            <p className='mt-6 fs-12'>{description || ''}</p>
                        </div>

                    </div>
                    <div className={'separator1px mt-10'} />
                </div>
            })
            }

            <SlidingPane
                isOpen={state.isPaneOpen}
                title="Назад к достопримечательностям"
                width={width < 450 ? '100%' : '60%'}
                subtitle=""
                onRequestClose={() => {
                    setCurrentItem(null)
                    setState({ isPaneOpen: false });
                }}>

                {currentItem && <div className={`wp-100 ${isUniversity ? 'university' : 'city'}`}>
                    <div className='d-flex-ac mb-15'>
                        <h4>{currentItem.title || ''}</h4>
                    </div>
                    <div className="form-group">
                        <InputLabeled label={'Название достопримечательности'} mandatory initValidation initValue={currentItem.title || ''}
                                      onChange={(value) => onChangeHandler('title', value)}/>
                    </div>

                    <div className='mt-10'>
                        <InputLabeled mandatory initValidation textarea label={'Описание достопримечательности'}
                                      onChange={(value) => onChangeHandler('description', value)} initValue={currentItem.description || ''}/>
                    </div>
                    <div className="form-group">
                        <InputLabeled label={'Адрес'} mandatory initValidation initValue={currentItem.address || ''}
                                      onChange={(value) => onChangeHandler('address', value)}/>
                    </div>
                    <div className='mt-10'>
                        <InputLabeled mandatory initValidation textarea label={'Контакты'}
                                      onChange={(value) => onChangeHandler('contacts', value)} initValue={currentItem.contacts || ''}/>
                    </div>
                    <div className="form-group">
                        <InputLabeled label={'широта'} type={'text'} initValue={currentItem?.lat || ''}
                                      onChange={(value) => onChangeHandler('lat', value)}/>
                    </div>
                    <div className="form-group">
                        <InputLabeled label={'долгота'} type={'text'} initValue={currentItem?.lng || ''}
                                      onChange={(value) => onChangeHandler('lng', value)}/>
                    </div>
                    <div className="form-group">
                        <p className={'mb-10'}>Участник программы "Васильковый край"</p>
                        <CheckBox checked={currentItem?.isCornflowerProgram || false}
                                  callBackChange={(value) => onChangeHandler('isCornflowerProgram', value)}/>
                    </div>
                    <div className="form-group">
                        <p className={'mb-10'}>Типы туризма</p>
                        <Multiselect placeholder={'Добавить'} showCheckbox={true}
                                     options={subTypesOptions}
                                     selectedValues={subTypesOptionsSelected}
                                     onSelect={(selectedList) => onSubTypeHandler(selectedList)}
                                     onRemove={(selectedList) => onSubTypeHandler(selectedList)}
                                     displayValue="name" // Property name to display in the dropdown options
                        />
                    </div>
                    <div className='mt-10 mb-20'>
                        <select onChange={(e) => onChangeHandler('type', e.target.value)} value={currentItem.type || ''}>
                            <option value={'sight'}>Достопримечательность</option>
                            <option value={'museum'}>Музей</option>
                            <option value={'sport'}>Спорт объект</option>
                        </select>
                    </div>
                    <div>
                        <ImageLabeled heading={newsImageText} initValue={currentItem.image || ''}
                                      onChange={(value) => onChangeHandler('image', value)} local/>
                    </div>

                    <div className='separator1px mt-10' />
                    <div className='mt-10 d-flex-sb'>
                        <button className={'button-link'} onClick={() => window.confirm('Вы уверены, что хотите удалить достопримечательность?') && deleteMapItem()}>Удалить</button>
                        <button className={'button'} onClick={() => updateMapItem()}>Обновить</button>
                        {/*<SaveButton />*/}
                    </div>
                </div>}

            </SlidingPane>
        </div>
    )
}

const mapStateToProps = ({ user: { data, section }, language: {texts}}) => {
    return { data, section, texts };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataItemUpdate: dataItemUpdate(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SightItem);
