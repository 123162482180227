import React, {useState} from 'react';

//import logo from "../../../assets/landing/mc-logo.png"
import bg from "../../../assets/landing/mu-header-img.svg"
import LoginPage from "../../login-page/login-page";
import {isUniversity} from "../../../utils/keys";

const Header = () => {

    const [expanded, setExpanded] = useState(false)

    const scrollToElement = (elem) => {
        document.getElementById(elem).scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <div className='landing-container'>
            <div className="displayMobile">
                <div className='mt-10 ml-10'>
                    <button
                        onClick={() => setExpanded(!expanded)}
                        className="button-link">
                        {expanded ? 'Закрыть окно входа' : 'Вход для факультетов'}</button>
                </div>
                {expanded &&
                    <div>
                        <LoginPage />
                    </div>
                }
            </div>
            <div className="landing-header">
                <div className="mt-30">
                    <div className="d-flex w-400">
                        <h1>Мой<br />университет</h1>
                    </div>
                    <div className="mt-20">
                        <p className='fs-12'>Разработано <a href="https://beltelecom.by/" target="_blank" rel="noopener noreferrer">РУП «Белтелеком»</a></p>
                    </div>
                    <div className="mt-50">
                        <h4 style={{lineHeight: 1.2}}><strong>Удобное студенчество</strong></h4>
                    </div>
                    <div className="mt-30 heading-font">
                        <ul>
                            <li>Расписание занятий</li>
                            <li>Онлайн-запись в деканат</li>
                            <li>События университета и факультета </li>
                            <li>Новости университета и факультета</li>
                            <li>Онлайн обращения в университет</li>
                            <li>Информация для студентов и абитуриентов</li>
                        </ul>
                    </div>
                    <div className="mt-50 heading-font">
                        <div>
                            <button onClick={() => scrollToElement("citizens")} className="button-blue w-150">УЗНАТЬ БОЛЬШЕ</button>
                        </div>

                    </div>
                </div>
                <div className="landing-header-image-box displayWeb">
                    <div className="landing-header-image">
                        <img src={bg} alt="bg" />
                    </div>
                    <div className="d-flex-end wp-100 mt-30 pabsolute">
                        <div>
                            <div>
                                <button
                                    onClick={() => setExpanded(!expanded)}
                                    className="button-link">
                                    {expanded ? 'Закрыть окно входа' : 'Вход для факультетов'}
                                </button>
                            </div>
                            {expanded &&
                                <div className="pabsolute" style={{right: 0}}>
                                    <LoginPage />
                                </div>
                            }
                        </div>
                    </div>
                    {!isUniversity && <p className="fs-10 fcgray ml-50 mt-50 pabsolute displayWeb">Первым пилотным городом<br/> на платформе стал город Полоцк</p>}
                </div>
            </div>
        </div>

    );

}


export default Header
