import React from 'react';
import Header from "./header";
import Citizens from "./citizens";
import Organizations from "./organizations";
import City from "./city";
import Footer from "./footer";
import {isUniversity} from "../../utils/keys";

const LandingUniver = () => {

    return (
        <div className={`landing-wrapper`}>
            <div className="separator" />
            <Header />
            {!isUniversity && <div>
                <Citizens />
                <Organizations />
                <City />
                <Footer />
            </div>}
        </div>
    );

}


export default LandingUniver
